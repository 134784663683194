import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import sevenponds from '../assets/images/landing/sevenponds2.jpg'
const Generic = (props) => (
<Layout>
   <Helmet>
      <title>S+J Venue</title>
      <meta name="description" content="Seven Ponds Info" />
   </Helmet>
   <div id="main" className="alt">
      <div className="inner">
         <header className="major">
            <h1>Seven Ponds Farm</h1>
         </header>
         <span className="image main"><img src={sevenponds} alt="" /></span>
         <div className="align-center">
            Seven Ponds is a real working farm in the Catskills. <a href="https://goo.gl/maps/MRU3QfxmSMr">Located in Summit, NY</a> on the highest plot of land in the county, you can see for miles and the views are spectacular.
         </div>
         <section id="one" className="venuetiles">
            <article>
               <div className="bgholder">
                  <header>
                     <h4>There are cows and goats and horses, oh my!</h4>
                  </header>
               </div>

            </article>
            <article>
               <div className="bgholder">
                  <header>
                     <h4>This could be you.</h4>
                  </header>
               </div>
            </article>
            <article>
               <div className="bgholder">
                  <header>
                     <h4>Fun Fact: The farmhouse and barn at Seven Ponds were built in the 18th century by George T. Lape, one of the engineers who helped build the Brooklyn Bridge in New York City.</h4>
                  </header>
               </div>
            </article>
            <article>
               <div className="bgholder">
                  <header>
                     <h4>And by the latest count, there are actually 14 ponds. It's a beautiful spot for a wedding (we think) and we hope you're able to come see for yourselves in June!</h4>
                  </header>
               </div>
            </article>
            <article>
               <a href="https://www.sevenpondsfarm.com" className="link primary" target="_blank" rel="noopener noreferrer">
                  <div className="bgholder">
                     <header>
                        <h3>For now take a look at Seven Ponds here</h3>
                     </header>
                  </div>
               </a>
            </article>
         </section>
      </div>
   </div>
</Layout>
)
export default Generic